<script>
console.clear();
import { notificationMethods } from "@/state/helpers";
import { required, integer } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: {
    currentEmpresa: {
      type: Object,
      required: true,
    },
    currentPacient: { type: Object, required: true },
    listAgreements: { type: Array, required: true },
    listResponsibles: { type: Array, required: true },
  },
  components: { Multiselect, ckeditor: CKEditor.component },
  data() {
    return {
      loader: {
        get: false,
      },
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Cadastros",
          href: "/cadastros",
          active: true,
        },
        {
          text: "Pacientes",
          href: "/cadastros/pacientes",
          active: true,
        },
      ],
      titleBody: "Alterando",
      inserindo: true,
      submit: false,
      pacient: {
        nome: "",
        cpf: "",
        pai: "",
        mae: "",
        responsable_id: null,
        contact: "",
      },
      responsible: {},
      editor: ClassicEditor,
    };
  },
  validations: {
    pacient: {
      nome: { required },
      cpf: { required },
      mae: { required },
      responsable_id: { required, integer },
    }
  },
  mounted() {
    this.$emit("newTitle", "Alterar Paciente");
    this.pacient.empresa_id = this.currentEmpresa.id;
    this.pacient = this.currentPacient;
    this.pacient.responsable_id = this.currentPacient.responsible.id;
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submit = true;
      this.$v.$touch();

      // stop here if form is invalid
      if (this.$v.pacient.$error) {
        if (!this.$v.pacient.responsable_id.required)
          this.makeToast("danger", "Responsável é obrigatório!")
          else        
            this.makeToast("danger", "Erro de preenchimento!");
      } else {
        const agreents = this.pacient.agreements.map((agreement) => agreement.id);
        this.pacient.agreements = agreents;
        this.setPut();
      }
    },
    setPut() {
      this.$emit("doPut", this.pacient);
    },
    back() {
      this.$emit("back");
    },
    changeLoader(value) {
      this.loader.get = value;
    },
    onSelectResponsible(selectedResponsible) {
      // Seta apenas o ID do responsável no model pacient.responsable_id
      this.pacient.responsable_id = selectedResponsible ? selectedResponsible.id : null;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="formSubmit" name="formPacient">
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="nome">{{ $t('mesages.forms.name_man') }} do {{ $t('resources.singular.pacient') }}:</label>
            <b-form-input
              id="nome"
              type="text"
              class="form-control"
              v-model="pacient.nome"
              :placeholder="$t('mesages.forms.name_man')+' '+$t('resources.singular.pacient')"
              :class="{ 'is-invalid': submit && $v.pacient.nome.$error }"
            >
            </b-form-input>
            <div v-if="submit && $v.pacient.nome.$error" class="invalid-tooltip">
              <span v-if="!$v.pacient.nome.required">{{ $t('mesages.forms.required')}}!</span>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label for="cpf">CPF DO PACIENTE:</label>
            <b-form-input
              id="cpf"
              type="tel"
              class="form-control"
              v-mask="'###.###.###-##'"
              v-model="pacient.cpf"
              placeholder="Ex: 12934567889"
              :class="{ 'is-invalid': submit && $v.pacient.cpf.$error }"
            ></b-form-input>
            <div v-if="submit && $v.pacient.cpf.$error" class="invalid-tooltip">
              <span v-if="!$v.pacient.cpf.required">{{ $t('mesages.forms.required')}}!</span>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label for="contact">CONTATO TELEFÔNICO:</label>
            <input
              id="contact"
              type="tel"
              class="form-control"
              v-mask="'## ##### ####'"
              v-model="pacient.contact"
              placeholder="Ex: 12934567889"
            />
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-6">
            <label for="mae">NOME DA MÃE:</label>
            <b-form-input
              type="text"
              class="form-control"
              v-model="pacient.mae"
              placeholder="Nome do mãe"
              :class="{ 'is-invalid': submit && $v.pacient.mae.$error }"
            ></b-form-input>
            <div v-if="submit && $v.pacient.mae.$error" class="invalid-tooltip">
              <span v-if="!$v.pacient.mae.required">{{ $t('mesages.forms.required')}}!</span>
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="pai">NOME DO PAI:</label>
            <input
              type="text"
              class="form-control"
              v-model="pacient.pai"
              placeholder="Nome do pai"
            />
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="responsable_id">NOME DO RESPONSÁVEL:</label>
            <multiselect v-model="pacient.responsible" :options="listResponsibles" :multiple="false"
             placeholder="Selecione"
             track-by="id"
             label="name"
             selectLabel='Pressione ENTER para selecionar'
             deselectLabel='Pressione ENTER para remover'
             :class="(submit && $v.pacient.responsable_id.$error) ? 'is-invalid' : ''"
             @input="onSelectResponsible"
            >
              <template #noResult>
                <span>Oops! Não encontrei, tente novamente.</span>
              </template>
            </multiselect>
          </div>
          <div v-if="submit && $v.pacient.responsable_id.$error" class="invalid-feedback">
            <span v-if="!$v.pacient.responsable_id.required">Responsável inválido</span>
          </div>

          <div class="form-group col-md-4">
            <label for="agreement_id">Convênio:</label>
            <Multiselect 
              v-model="pacient.agreements"
              :options="listAgreements"
              :multiple="true"
              track-by="id"
              label="name"
              :taggable="true"
              selectLabel='Pressione ENTER para selecionar'
              deselectLabel='Pressione ENTER para remover'
              placeholder="Selecione"
            >
              <template #noResult>
                <span>Oops! Não encontrei, tente novamente.</span>
              </template>
            </Multiselect>
          </div>
          <div class="form-group col-md-2">
            <label for="is_private">PARTICULAR?</label>
            <input
              type="checkbox"
              class="form-control"
              v-model="pacient.is_private"
              placeholder="Sim/Não"
            />
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <div class="card-body">
            <h4 class="card-title">Observações:</h4>
            <!-- Editor -->
            <ckeditor v-model="pacient.observations" :editor="editor"></ckeditor>
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <button
            type="submit"
            class="btn btn-primary"
            style="margin-right: 20px"
          >
            Gravar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>