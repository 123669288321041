<script>
import appConfig from "@/app.config";
import {http} from '@/helpers/easyindustriaapi/config';
import PacientController from '@/controller/PacientController';
import Layout from "@/views/layouts/main"
import PageHeader from "@/components/page-header";
import List from "./list.vue";
import Insert from "./insert.vue";
import Edit from "./edit.vue";
import Swal from "sweetalert2";
  
export default {
  page: {
    title: 'Lista de Pacientes',
    meta: [{
      name: 'description',
      content: appConfig.description
    }],
  },
  components: {List, Insert, Edit, PageHeader, Layout},
  data() {
    return {
      pacients: [],
      listAgreements: [],
      listResponsibles: [],
      inserindo: false,
      editando: false,
      titleBody: 'Lista de Pacientes',
      items: [{
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Paciente",
          href: "/",
          active: true,
        },
        {
          text: "Incluir Paciente",
          href: "/cadastros/fichas",
          active: true,
        }
      ]
    }
  },
  computed: {
    isHide() {
      return (!this.inserindo && !this.editando);
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    this.PacientController = new PacientController();
  },
  mounted() {
    },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display != "block") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function() {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    getData(ctx) {
      const filter = (ctx.filter == null ? '' : ctx.filter);
      const promise = http.get(
        `/pacient?empresa_id=${this.currentEmpresa.id}
        &per_page=${ctx.perPage}
        &page=${ctx.currentPage}
        &filter=${filter}
        &orderBy=${ctx.sortBy}
        &sortDesc=${ctx.sortDesc}`
      );
      return promise.then((response) => {
        this.pacients = response.data.pacients;
        // Pluck the array of items off our axios response
        const items = response.data.pacients.data;
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
      .catch((error) => {
        let messageErro = "";
        console.log(error);
        if (error.response && error.response.data) {
          messageErro = error.response.data;
        }
        switch (error.response ? error.response.status : null) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo ? messageErro.tiponome : messageErro.tpag));
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      })
      .finally(() => {
      });
    },
    insert() {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando dados...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();// Exibe o loader enquanto a requisição está em andamento
        }
      });

      const promise = http.get('/pacient/insert?empresa_id=' + this.currentEmpresa.id);
      
      return promise.then((response) => {
        this.listAgreements = response.data.agreements;
        this.listResponsibles = response.data.responsibles;
        // Pluck the array of items off our axios response
        const items = response.data;
        Swal.close(); // Fecha o loader
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
      .catch((error) => {
        Swal.close(); // Fecha o loader
        let messageErro = "";
        console.log(error);
        
        if (error.response && error.response.data) {
          messageErro = error.response.data;
        }

        switch (error.response ? error.response.status : null) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo ? messageErro.tiponome : messageErro.tpag));
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      })
      .finally(() => {
        this.inserindo = true;
      });
    },
    edit(value) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando dados...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });

      const promise = http.get('/pacient/edit?empresa_id=' + this.currentEmpresa.id);

      return promise.then((response) => {
        this.listAgreements = response.data.agreements;
        this.listResponsibles = response.data.responsibles;
        // Pluck the array of items off our axios response
        const items = response.data;
        Swal.close(); // Fecha o loader
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
      .catch((error) => {
        Swal.close(); // Fecha o loader
        let messageErro = "";
        console.log(error);
        
        if (error.response && error.response.data) {
          messageErro = error.response.data;
        }

        switch (error.response ? error.response.status : null) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo ? messageErro.tiponome : messageErro.tpag));
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      })
      .finally(() => {
        this.currentPacient = value;
        this.editando = !this.editando;
      });
    },
    doPost(pacient) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Estamos processando sua solicitação.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });

      // Define valores e envia para API
      pacient.empresa_id = this.currentEmpresa.id;
      const promise = http.post(`/pacient?empresa_id=${this.currentEmpresa.id}`, pacient);

      return promise.then((response) => {
        this.successmsg();
        this.back();
        return response;
      })
      .catch((error) => {
        Swal.close(); // Fecha o loader
        console.log(error);
        let messageErro = "";

        if (error.response && error.response.data) {
          messageErro = error.response.data;
        }

        switch (error.response ? error.response.status : null) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo ? messageErro.tiponome : messageErro.tpag));
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          case 400:
            this.makeToast('danger', 'Erro 400: Dados inválidos!');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      })
      .finally(() => {
      });
    },
    doPut(pacient) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Estamos processando sua solicitação.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });

      pacient.user_id = this.currentUser.id;
      const promise = http.put('/pacient/' + pacient.id + '?empresa_id=' + this.currentEmpresa.id, pacient);
      return promise.then((response) => {
        if (response.status == 200)
        {
          this.successmsg();
          this.back();
          return response;
        }
      })
      .catch((error) => {
        Swal.close(); // Fecha o loader
        console.log(error.response.data);
        const errors = error.response.data;

        switch (error.response ? error.response.status : null) {
          case 406:
            if (errors.responsible_id) {
              this.makeToast('danger', 'Erro 406: ' + (errors.responsible_id[0]));
            }
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          case 400:
            this.makeToast('danger', 'Erro 400: Dados inválidos!');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      });
    },
    async doDelete(pacient) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Estamos processando sua solicitação.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });

      pacient.empresa_id = this.currentEmpresa.id;
      const promisse = http.delete(`/pacient/${pacient.id}?empresa_id=${this.currentEmpresa.id}`, pacient);

      return promisse.then((response) => {
        this.inserindo = true;
        this.inserindo = false;
        this.getData();
        this.back();
        Swal.close();
        this.makeToast('warning', 'Registro excluído');
        return response;
      }).catch((error) => {
        console.log(error);
        let timerInterval;
        Swal.fire({
          icon: 'error',
          title: 'Erro ao tentar Excluir!',
          text: error.response,
          timer: 3000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${Swal.getTimerLeft()}`;
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      });
    },
    setResponsibles(list) {
      this.listResponsibles = list;
    },
    doFiles(file) {
      const result = this.PacientController.getFiles(file);
      console.log(result);
    },
    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Registro gravado com sucesso!",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
}
</script>

<template>
  <Layout> 
  <PageHeader :title="titleBody" :items="items" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Paciente</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="!isHide">
          <Insert
           v-if="inserindo"
           :currentEmpresa="currentEmpresa"
           :listAgreements="listAgreements"
           :listResponsibles="listResponsibles"
           @newTitle="changeHomeTitle"
           @doPost="doPost"
           @onLoader="onLoader"
           @offLoader="offLoader"
           @setResponsibles="setResponsibles"
          />
          <Edit
           v-if="editando"
           :currentEmpresa="currentEmpresa"
           :currentPacient="currentPacient"
           :listAgreements="listAgreements"
           :listResponsibles="listResponsibles"
           @setResponsibles="setResponsibles"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />
        </div>
        <List v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :pacients="pacients"
         @newTitle="changeHomeTitle"
         @edit="edit"
         @doDelete="doDelete"
         :getData="getData"
         @getFiles="doFiles"
        />
      </div>
    </div>
  </div>
  </Layout> 
</template>