<script>
import PacientController from '@/controller/PacientController';
import Swal from "sweetalert2";

export default {
  props: {
    currentPacient: { type: Object },
  },
  data() {
    return {
      file: null,
      files: [],
      isShow: true,
      textButton: 'Carregar',
    }
  },
  computed: {},
  created() {
    this.PacientController = new PacientController();
  },
  mounted() {
    // this.getFiles();
  },
  methods: {
    async getFiles() {
      this.isShow = false;
      this.currentPacient.arquivos = await this.PacientController.getFiles(this.currentPacient);
      this.isShow = true;
    },
    async doDelete(id) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando dados...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });
      await this.PacientController.delete(id);
      await this.getFiles();
      Swal.close();
    },
    async doDownload(file) {
      const $file = await this.PacientController.downloadFile(file);
      console.log($file);
    },
    setFile() {
      this.file = this.$refs.file.files[0];
      this.doUpload();
    },
    async doUpload() {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando dados...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });
      const $file = await this.PacientController.uploadFile(this.file, this.currentPacient).catch((error) => {
        Swal.close();
        Swal.fire({ title: 'Erro ao carregar o arquivo', text: error.response.data.message, icon: 'error' });
      });
      this.currentPacient.arquivos.push($file);
      await this.getFiles();
      this.$refs.file.value = null;
      Swal.close();
    }
  }
}
</script>

<template>
<div class="paciente">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div role="tablist">
            <b-card no-body class="mb-1 shadow-none">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-1
                    class="text-dark"
                    href="javascript: void(0);"
                  >Dados gerais</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="pacient-accordion" role="tabpanel">
                <b-card-body>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 border-detail m-2 p-2">Código: <strong>{{ currentPacient.id }}</strong></div>
                    <div class="col-md-6 col-sm-6 border-detail m-2 p-2">Nome: <strong>{{ currentPacient.nome }}</strong></div>
                  </div>
                  <div class="row">
                      <div class="col-md-5 col-sm-12 border-detail m-2 p-2">Nome da Mãe: <strong>{{ currentPacient.mae  }}</strong></div>
                      <div class="col-md-6 col-sm-12 border-detail m-2 p-2">Nome do Pai: <strong>{{ currentPacient.pai  }}</strong></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 col-sm-12 border-detail m-2 p-2">C. P. F.: <strong>{{ currentPacient.cpf  }}</strong></div>
                    <div class="col-md-4 col-sm-12 border-detail m-2 p-2">Contato: <strong>{{ currentPacient.contact }}</strong></div>
                    <div class="col-md-2 col-sm-12 border-detail m-2 p-2">
                      É particular?: <b-badge class="pl-2 ml-2" variant="success" v-if="currentPacient.is_private == 1">Sim</b-badge>
                      <b-badge class="ml-2" variant="danger" v-else>Não</b-badge>
                    </div>
                  </div>
                  <div class="row border-detail m-2 p-2">
                    <p class="card-text text-primary"><strong>Observação: </strong></p>
                    <p class="card-text" v-html="currentPacient.observations"></p>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Convênios</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="pacient-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row" v-for="agreement in currentPacient.agreements" :key="agreement.id">
                      <div class="col-md-12 border-detail m-2 p-2">
                        <span class="m-2 p-2">ID: <strong>{{ agreement.id }}</strong></span>
                        <span class="m-2 p-2">Nome: <strong>{{ agreement.name }}</strong></span>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
            </b-card>
            <b-card v-if="isShow" no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-3
                      class="text-dark"
                      href="javascript: void(0);"
                    >Arquivos</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="pacient-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-md-1 text-center">Código</div>
                      <div class="col-md-5 text-center">Nome</div>
                      <div class="col-md-3 text-center">Criado em</div>
                      <div class="col-md-2 text-center">Ações</div>
                    </div>
                    <div class="row" v-for="file in currentPacient.arquivos" :key="file.id">
                      <div class="col-md-1 text-right">
                        {{ file.id }}
                      </div>
                      <div class="col-md-5">
                        {{ file.nome }}
                      </div>
                      <div class="col-md-3 text-center">
                        {{ formatDateTimeBR(file.created_at) }}
                      </div>
                      <div class="col-md-2 text-center">
                        <a href="javascript: void(0);"><i class="ri-delete-bin-fill ml-2 text-danger" @click="doDelete(file.id)"></i></a>
                        <a :href="file.url" target="_blank"><i class="ri-download-cloud-fill ml-2 text-primary"></i></a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 pt-3 mt-3">
                        <div class="custom-file">
                          <input id="customFile" @change="setFile" type="file" class="custom-file-input" ref="file"/>
                          <label class="custom-file-label" for="customFile">Selecionar arquivo</label>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p>Criado em: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ formatDateTimeBR(currentPacient.created_at) }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ formatDateTimeBR(currentPacient.updated_at) }}</b-badge></p>
  <p>Alteração feita por: <b-badge data-name="user-name" class="field-updated_at" variant="secondary">{{ (currentPacient.user) ? currentPacient.user.name : '' }}</b-badge></p>
</div>
</template>