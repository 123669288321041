<script>
import Swal from "sweetalert2";
import Detail from "./detail";

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    pacients: { required: true },
    getData: { type: Function, required: true },
  },
  components: { Detail },
  data() {
    return {
      titleBody: 'Listagem dos Pacientes',
      currentPaciente: undefined,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [3,10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: "ID", key: "id", sortable: true, tdClass: 'text-center', thClass: 'text-center' },
        { label: 'Nome', key: 'nome', sortable: true, thClass: 'text-center' },
        { label: "Celular", key: "contact", sortable: true, thClass: 'text-center' },
        { label: "Mãe", key: "mae", sortable: true, thClass: 'text-center' },
        { label: "Particular", key: "is_private", sortable: true, thClass: 'text-center', tdClass: 'text-center' },
        { label: "Ações", key: "actions", sortable: false, tdClss: 'text-center', thClass: 'text-center' }
      ],
      isLoading: false
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return (this.pacients.data) ? this.pacients.total : 0;
    },
    clonefoot() {
      return (this.pacients.total || this.pacients.total > 10) ? true : false;
    },
  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
    // this.totalRows = (this.pacients) ? this.pacients.length : 0;
  },
  created(){
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editar(currentPaciente) {
      this.$emit('edit', currentPaciente);
    },
    excluir(currentPaciente) {
      Swal.fire({
        title: 'Deseja realmente excluir o Paciente?',
        text: "'" + currentPaciente.nome + "'",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close();
          this.$emit('doDelete', currentPaciente);
        }
      });
    },
    setPaciente(currentPaciente) {
      console.log(currentPaciente);
      this.currentPaciente = currentPaciente;
    },
    getFiles(file) {
      this.$emit('getFiles', file);
    }
  },
}
</script>

<template>
<div class="card-body">
  <div class="row mt-4">
    <!-- Start Limit -->
    <div class="col-sm-12 col-md-6">
      <div id="responsible-table_length" class="dataTables_length">
        <label class="d-inline-flex align-items-center">
          Exibir&nbsp;
          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
        </label>
      </div>
    </div>
    <!-- End Limit -->
    <!-- Start Search -->
    <div class="col-sm-12 col-md-6">
      <div id="responsible-table_filter" class="dataTables_filter text-md-right">
        <label class="d-inline-flex align-items-center">
          Pesquisar:
          <b-form-input
          v-model="filter"
          type="search"
          placeholder="Pesquisar..."
          class="form-control form-control-sm ml-2"
          ></b-form-input>
        </label>
      </div>
    </div>
    <!-- End search -->
  </div>
   <!-- Start Table -->
   <div class="row mt-4">
    <div class="table-responsive mb-0">
      <b-table
       ref="table"
       :items="getData"
       :fields="fields"
       responsive="sm"
       :per-page="perPage"
       :current-page="currentPage"
       :sort-by.sync="sortBy"
       :sort-desc.sync="sortDesc"
       :filter="filter"
       :filter-included-fields="filterOn"
       @filtered="onFiltered"
       :hover=true
       :foot-clone=clonefoot
       :busy.sync="isLoading"
      >
        <template #cell(is_private)="row">
          {{ (row.item.is_private === 1) ? 'Sim' : 'Não' }}
        </template>
        <template #cell(actions)="row">
          <!-- `data.value` is the value after formatted by the Formatter -->
          <b-dropdown v-bind:id="'dropdown-'+ row.item.id" class="m-md-2">
            <template #button-content>
              <i data-v-6289eca4="" class="bx ri-menu-line"></i>
            </template>
            <b-dropdown-item v-b-modal.modal-pacient
             @click="setPaciente(row.item)"
            >
              <i class="bx ri-eraser-fill"></i> Visualizar
            </b-dropdown-item>
            <b-dropdown-item @click="editar(row.item)"><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item>
            <b-dropdown-item @click="excluir(row.item)"><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item>
          </b-dropdown>
        </template>
        <template #table-busy><!-- spinner while loading -->
        <div class="text-center text-warning my-2">
          <b-spinner class="align-middle mr-3 "></b-spinner>
          <strong>{{ $t("mesages.table.loader") }}...</strong>
        </div>
      </template>
      </b-table>
    </div>
   </div>
   <!-- End Table -->
   <!-- Start Pagination -->
  <div class="row mt-4">
    <div class="col">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Pagination -->

  <b-modal
    v-if="currentPaciente"
    hide-footer
    id="modal-pacient"
    title-class="font-18"
    size="xl"
  >
    <template #modal-title>
      <b-container class="row-flex-cols">
        <b-row align-v="start" class="h3">
          <b-col><i class="ri-parent-fill text-warning"></i></b-col>
          <b-col><span class="font-weight-bold">Paciente</span></b-col>
        </b-row>
      </b-container>
    </template>
    <Detail :currentPacient="currentPaciente" @getFiles="getFiles" />
    <p></p>
  </b-modal>
</div>
</template>