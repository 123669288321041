import { http } from "../helpers/easyindustriaapi/config";

export default class PacientController {
    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    }

    getFiles(pacient) {
        const promisse = http.get(`/arquivo/pacient/${pacient.id}?empresa_id=${this.currentEmpresa.id}`);
        return promisse.then((response) => {
            const items = response.data.data;
            return items || []
        }).catch((error) => {
            console.log(error);
            throw this.handleError(error);
        });
    }
    async uploadFile(file, pacient) {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const headers = { 'Content-Type': 'multipart/form-data' };
            const response = await http.post(
                `/arquivo/${pacient.id}?empresa_id=${this.currentEmpresa.id}`, 
                formData,
                { headers }
            );
            return response.data.data;
        } catch (error) {
            console.log(error);
            throw this.handleError(error);
        }
    }

    async delete(id) {
        try {
            const response = await http.delete(`/arquivo/${id}?empresa_id=${this.currentEmpresa.id}`);
            return response.data;
        } catch (error) {
            console.log(error);
            throw this.handleError(error);
        }
    }

    downloadFile(file) {
        const promisse = http.get(`/arquivo/${file.id}?empresa_id=${this.currentEmpresa.id}`, file);
        return promisse.then((response) => {
            const item = response.data;
            return item || {}
        }).catch((error) => {
            console.log(error);
            throw this.handleError(error);
        });
    }
}